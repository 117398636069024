import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";
import axios from "axios";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";
import Form from "./components/Form";

const Contact = () => {
  const [validated, setValidated] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [displayContactFormAlert, setDisplayContactFormAlert] = useState(false);
  const [contactFormAlertType, setContactFormAlertType] = useState(null);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      axios({
        method: "Post",
        url: `${process.env.REACT_APP_API_URL}/contact`,
        data: {
          name: contactForm.name,
          email: contactForm.email,
          subject: contactForm.subject,
          message: contactForm.message,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            setContactFormAlertType("success");
            setDisplayContactFormAlert(true);
            form.reset();
            setValidated(false);
          } else if (response.data.status === "fail") {
            setContactFormAlertType("danger");
            setDisplayContactFormAlert(true);
            console.error("Error: Server returned a fail status");
          } else {
            throw new Error(
              "Error: Unknown response returned from server with response data: ",
              response.data
            );
          }
        })
        .catch((error) => {
          setContactFormAlertType("danger");
          setDisplayContactFormAlert(true);
          console.error("Unknown Error with message: ", error);
        });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setContactForm({ ...contactForm, [event.target.name]: value });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Aspiricity - Contact Us</title>
      </Helmet>
      <Header>
        <h2>Contact Us</h2>
      </Header>
      <Section bgColor="bg-light">
        <p>
          Got a question about Aspiricity? You’ve come to the right place!
          Please fill out the form below, we'd love to hear from you.
        </p>
        <Form
          validated={validated}
          onSubmit={handleSubmit}
          onChange={handleChange}
        />
        {displayContactFormAlert && (
          <Alert
            variant={contactFormAlertType}
            onClose={() => setDisplayContactFormAlert(false)}
            dismissible
          >
            {contactFormAlertType === "success"
              ? "Your message has been sent successfully! We will get back to you shortly."
              : "We were unable to send your message, please try again or send an email to info@aspiricity.com. Thanks!"}
          </Alert>
        )}
        <br />
      </Section>
      <Footer contactPage /> 
    </React.Fragment>
  );
};

export default Contact;
