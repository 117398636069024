import React from "react";
import { Link } from "react-router-dom";

const Anchor = (props) => {
    console.log(props.to);
    if (!props.to) return <span>{props.children}</span>;
    if (/^https?:\/\//.test(props.to)) return <a href={props.to}>{props.children}</a>;
    return <Link to={props.to}>{props.children}</Link>
}

export default Anchor;