import React from "react";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Carousel from "./components/Carousel";
import CardGroup from "./components/CardGroup";
import slides from "./data/slides.json";
import services from "./data/services.json";
import Section from "./components/Section";

const Home = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Aspiricity - Aspire Higher</title>
      </Helmet>
      <Header>
        <Carousel slides={slides}/>
      </Header>
      <Section bgColor="bg-light" textColor="text-dark" title="Our Services">
        <CardGroup cards={services} />
        <br />
      </Section>
      <Footer/>
    </React.Fragment>
  );
};

export default Home;
