import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const SectionWrapper = styled.div`
   {
    padding: 2rem;

    .container {
      h2 {
        text-align: center;
        padding: 1.5rem;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif;
      }
      h3 {
        padding: 1rem 0;
        font-family: "Open Sans", sans-serif;
      }
      a {
        color: #535C66;
        &:hover {
          color: #292E33;
          -webkit-font-smoothing: antialiased;
          text-decoration: none;
        }
        &:focus {
          color: #292E33;
        }
      }
      a.btn {
        color: #fff;
      }
      a.alert {
        color: #000;
      }

      div.ghost-text {
        a {
        color: #6FA4D9;
        &:hover {
          color: #6290BF;
          -webkit-font-smoothing: antialiased;
          text-decoration: none;
        }
        &:focus {
          color: #4E7499;
        }
      }
      }
    }
  }
`;

const Section = (props) => {
  const sectionStyle = `${props.bgColor} ${props.textColor}`;
  return (
    <SectionWrapper className={sectionStyle}>
      <Container>
        {props.title && <h2>{props.title}</h2>}
        {props.children}
      </Container>
    </SectionWrapper>
  );
};

export default Section;
