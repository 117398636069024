import React from "react";
import styled from "styled-components";
import { Form as BootstrapForm, Button } from "react-bootstrap";

const FormWrapper = styled.div`
   {
    text-align: left;
    padding: 1rem;
  }
`;

const Form = (props) => {
  return (
    <FormWrapper>
      <BootstrapForm noValidate validated={props.validated} onSubmit={props.onSubmit}>
        <BootstrapForm.Group controlId="subscribeFormFirstName">
          <BootstrapForm.Label>First Name</BootstrapForm.Label>
          <BootstrapForm.Control
            type="text"
            name="firstName"
            placeholder="Enter first name"
            onChange={props.onChange}
            required
          />
          <BootstrapForm.Control.Feedback type="invalid">
            Please provide your first name.
          </BootstrapForm.Control.Feedback>
        </BootstrapForm.Group>
        <BootstrapForm.Group controlId="subscribeFormLastName">
          <BootstrapForm.Label>Last Name</BootstrapForm.Label>
          <BootstrapForm.Control
            type="text"
            name="lastName"
            placeholder="Enter last name"
            onChange={props.onChange}
            required
          />
          <BootstrapForm.Control.Feedback type="invalid">
            Please provide your last name.
          </BootstrapForm.Control.Feedback>
        </BootstrapForm.Group>
        <BootstrapForm.Group controlId="subscribeFormEmail">
          <BootstrapForm.Label>Email address</BootstrapForm.Label>
          <BootstrapForm.Control
            type="email"
            name="emailAddress"
            placeholder="Enter email address"
            onChange={props.onChange}
            required
          />
          <BootstrapForm.Text className="text-muted">
            We'll never share your personal information with anyone.
          </BootstrapForm.Text>
          <BootstrapForm.Control.Feedback type="invalid">
            Please provide a valid email address.
          </BootstrapForm.Control.Feedback>
        </BootstrapForm.Group>
         <Button variant="primary" type="submit">
          Submit
        </Button>{" "}
        <Button variant="secondary" type="reset">
          Clear
        </Button>
      </BootstrapForm>
        {props.title && <h2>{props.title}</h2>}
        {props.children}
    </FormWrapper>
  );
};

export default Form;
