import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Home from "./Home";
import About from "./About";
import Blog from "./Blog";
import Post from "./Post";
import Contact from "./Contact";
import Consulting from "./Consulting";
import Subscribe from "./Subscribe";
import Training from "./Training";
import NotFound from "./NotFound";
import NavBar from "./components/NavBar";

const AppContainer = styled.div`
  {
  background-color: #343a40;
  height : 100%;
  }
`;

function App() {
  return (
    <AppContainer>
      <NavBar />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} />
        <Route path="/blog" component={Blog} exact />
        <Route path="/blog/post/:slug" component={Post}/>
        <Route path="/contact" component={Contact} />
        <Route path="/consulting" component={Consulting} />
        <Route path="/subscribe" component={Subscribe} />
        <Route path="/training" component={Training} />
        <Route component={NotFound} />
      </Switch>
    </AppContainer>
  );
}

export default App;
