import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Alert, Row, Col, Image } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";
import CardGroup from "./components/CardGroup";
import { longDateFormat } from './utils/dates';

const FeaturedPost = (props) => {
  const postImage =
    props.post.feature_image ||
    "https://source.unsplash.com/featured/?technology&ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000&fit=max&ixid=eyJhcHBfaWQiOjExNzczfQ";
  return (
    <React.Fragment>
      <Row className="justify-content-md-center align-items-center">
        <Col md={8}>
          <Link to={`/blog/post/${props.post.slug}/`}>
            <Image src={postImage} alt={props.post.title} fluid />
          </Link>
        </Col>
        <Col className="flex-column justify-content-md-center">
          <h1>
            <Link to={`/blog/post/${props.post.slug}`}>{props.post.title}</Link>
          </h1>
          <br />
          <p className="lead">
            <Link to={`/blog/post/${props.post.slug}`}>{props.post.excerpt}</Link>
          </p>
          <p>{longDateFormat(new Date(props.post.published_at).toDateString())} | {props.post.reading_time} min read</p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const Blog = () => {
  const [data, setData] = useState({ posts: [] });
  const [featured, setFeatured] = useState();
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/ghost/posts/`
        );
        setData(result.data);
      } catch (error) {
        console.log(error);
        setDisplayError(true);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const featuredIndex = data.posts.findIndex((post) => {
      return post.featured;
    });
    featuredIndex !== -1
      ? setFeatured(data.posts[featuredIndex])
      : setFeatured(data.posts[0]);
  }, [data]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Aspiricity - Tech Nomad Blog</title>
      </Helmet>
      <Header>
        <h1>The Tech Nomad Blog</h1>
      </Header>
      <Section bgColor="bg-light" textColor="text-dark">
        {!featured &&
          !displayError &&
          "Please wait while the Tech Nomad packs the digital donkeys..."}
        {featured && (
          <React.Fragment>
            <p className="lead">
              The Tech Nomad blog is a useful sojourn on your tech journey, with
              videos, tutorials and all our latest software dev and testing
              news.
            </p>
            <FeaturedPost post={featured}></FeaturedPost>
            <hr />
            <CardGroup
              cards={data.posts.filter((post) => post.id !== featured.id)}
            />
            <br />
          </React.Fragment>
        )}
        {displayError && (
          <Alert
            variant="danger"
            onClose={() => setDisplayError(false)}
            dismissible
          >
            Uh oh! It seems like the Tech Nomad has wandered off course. We're
            sure he'll get back on track shortly, but until then head back to
            the <Link to={"/"}>Aspiricity home page</Link>.
          </Alert>
        )}
      </Section>
      <Footer />
    </React.Fragment>
  );
};

export default Blog;
