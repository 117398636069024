import React from "react";
import {
  Card as BootstrapCard,
  CardDeck,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Anchor from "./Anchor";
import AnchorButton from "./AnchorButton";
import { longDateFormat } from '../utils/dates';
const images = require.context("../assets", true);

const Card = (props) => {
  const description = props.data.description || props.data.excerpt;
  const title = props.data.title;
  const blogUrl = props.data.slug ? `/blog/post/${props.data.slug}` : null;
  const url = props.data.button ? props.data.button.url : blogUrl;
  const buttonLabel = props.data.button ? props.data.button.label : null;
  const publishDate = props.data.published_at ? new Date (props.data.published_at) : null;
  const badgeLabel = props.data.badge ? props.data.badge.label : null;

  let imagePNG, imageWEBP, imageJP2;
  if (props.data.image){
    imagePNG = images(`./${props.data.image}.png`);
    imageWEBP = images(`./${props.data.image}.webp`);
    imageJP2 = images(`./${props.data.image}.jp2`);  
  }
  return (
    <BootstrapCard>
      {props.data.image && (
        <picture>
          <source type="image/webp" srcSet={imageWEBP} />
          <source type="image/jp2" srcet={imageJP2} />
          <BootstrapCard.Img
            variant="top"
            src={imagePNG}
            alt={title}
          />
        </picture>
      )}
      {props.data.feature_image && (
        <Link to={url}><BootstrapCard.Img variant="top" src={props.data.feature_image} alt={title} /></Link>
      )}
      <BootstrapCard.Body>
        <BootstrapCard.Title><Anchor to={url}>{title}</Anchor></BootstrapCard.Title>
        <BootstrapCard.Text><Anchor to={url}>{description}</Anchor></BootstrapCard.Text>
        {buttonLabel && (<AnchorButton to={url}>{buttonLabel}</AnchorButton>)}
        {badgeLabel && <span className="badge badge-secondary">{badgeLabel}</span>}
      </BootstrapCard.Body>
      {props.data.published_at && (
        <BootstrapCard.Footer>
          {longDateFormat(publishDate.toDateString())} | {props.data.reading_time} min read
        </BootstrapCard.Footer>
      )}
    </BootstrapCard>
  );
};

const CardGroup = (props) => {
  const cardrows = []
  const cards = [...props.cards];
  cards.splice(9, cards.length-1);
  while (cards.length) cardrows.push(cards.splice(0,3));

  return (
    <Container>
      {cardrows.map((cardrow) => (
        <React.Fragment>
          <CardDeck>
            {cardrow.map((data) => (
              <Card data={data} key={data.id} />
            ))}
          </CardDeck>
          <br/>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default CardGroup;
