import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { SocialMediaIconsReact } from "social-media-icons-react";

const FooterContainer = styled.div`
   {
    background: #343a40;
    padding: 2rem 0;
    color: white;
    text-align: center;
    text-shadow: #000000 1px 1px 3px;
  }
`;

const Footer = (props) => {
  return (
    <FooterContainer>
      <Container>
        {!props.contactPage && (<React.Fragment><p className="lead">
          Ready to take your software development abilities to the next level?
        </p>
        <p>
        <Button as={Link} variant="primary" to="/subscribe">
          Subscribe now
        </Button>           
        {' '}
        <Button as={Link} variant="primary" to="/contact">
          Contact us today
        </Button>
        </p></React.Fragment>)}
        <p>
          Follow us on:
          {' '}
          <SocialMediaIconsReact
            icon="facebook"
            url="https://www.facebook.com/aspiricity/"
            iconSize="1"
            size="40"
          />
          {' '}
          <SocialMediaIconsReact
            icon="twitter"
            url="https://twitter.com/aspiricity"
            iconSize="1"
            size="40"
          />
          {' '}
          <SocialMediaIconsReact
            icon="linkedin"
            url="https://www.linkedin.com/company/aspiricity/"
            iconSize="1"
            size="40"
          />
          {' '}
          <SocialMediaIconsReact
            icon="youtube"
            url="https://www.youtube.com/channel/UCL2ITgxLyogPfshNmkOuGUw/"
            iconSize="1"
            size="40"
          />
        </p>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
