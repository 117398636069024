import React from "react";
import styled from "styled-components";
import headerImage from "../assets/headerImage.jpg";

const HeaderContainer = styled.div`
   {
    background: linear-gradient(rgba(37,92,179,.25), rgba(37,92,179,0.15)), url(${headerImage}) no-repeat scroll center;
    background-size: cover;
    z-index: -2;

    padding: 6rem 0 4rem 0;
    color: white;
    text-align: center;
    text-shadow: #000000 1px 1px 3px;
  }
`;

const Header = props => {
  return (
    <HeaderContainer>
      {props.children}
    </HeaderContainer>
  );
};

export default Header;
