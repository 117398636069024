import React from "react";
import { Link } from "react-router-dom";
import {
  Carousel as BootstrapCarousel,
  Container,
  Row,
  Col,
  Image,
  Button,
  
} from "react-bootstrap";
const images = require.context("../assets", true);

const Slide = (props) => {
  const imagePNG = images(`./${props.data.image}512x384.png`);
  const imageWEBP = images(`./${props.data.image}512x384.webp`);
  const imageJP2 = images(`./${props.data.image}512x384.jp2`);
  return (
    <React.Fragment>
      <Row className="justify-content-md-center align-items-center">
        <Col md={4}>
          <picture>
              <source type="image/webp" srcSet={imageWEBP} />
              <source type="image/jp2" srcSet={imageJP2} />
              <Image src={imagePNG} fluid alt={props.data.image} />
          </picture>
        </Col>
        <Col className="flex-column justify-content-md-center">
          <h1>{props.data.title}</h1>
          {props.data.description && <p className="lead">{props.data.description}</p>}
          {props.data.button && (
            <Button as={Link} variant="primary" to={props.data.button.url}>
              {props.data.button.label}
            </Button>
          )}
        </Col>
      </Row>
      <br />
      <br />
    </React.Fragment>
  );
};

const Carousel = (props) => {
  return (
    <Container>
      <BootstrapCarousel>
        {props.slides.map((data) => (
          <BootstrapCarousel.Item key={data.id}>
            <Slide data={data} />
          </BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </Container>
  );
};

export default Carousel;
