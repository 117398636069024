import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";
import axios from "axios";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";
import SubscribeForm from "./components/SubscribeForm";

const Subscribe = () => {
  const [validated, setValidated] = useState(false);
  const [subscribeForm, setSubscribeForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });
  const [displaySubscribeFormAlert, setDisplaySubscribeFormAlert] = useState(false);
  const [subscribeFormAlertType, setSubscribeFormAlertType] = useState(null);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      axios({
        method: "Post",
        url: `${process.env.REACT_APP_API_URL}/mailchimp`,
        data: {
          firstName: subscribeForm.firstName,
          lastName: subscribeForm.lastName,
          emailAddress: subscribeForm.emailAddress,
        },
      })
        .then((response) => {
          if (response.data.status === "subscribed") {
            setSubscribeFormAlertType("success");
            setDisplaySubscribeFormAlert(true);
            form.reset();
            setValidated(false);
          } else if (response.data.status === "fail") {
            setSubscribeFormAlertType("danger");
            setDisplaySubscribeFormAlert(true);
            console.error("Error: Server returned a fail status");
          } else {
            throw new Error(
              "Error: Unknown response returned from server with response data: ",
              response.data
            );
          }
        })
        .catch((error) => {
          setSubscribeFormAlertType("danger");
          setDisplaySubscribeFormAlert(true);
          console.error("Unknown Error with message: ", error);
        });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSubscribeForm({ ...subscribeForm, [event.target.name]: value });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Aspiricity - Subscribe</title>
      </Helmet>
      <Header>
        <h2>Subscribe to Aspiricity's Mailing List</h2>
      </Header>
      <Section bgColor="bg-light">
        <p>
          Get notified on the latest news, blog posts and YouTube videos from Aspiricity! Subscribe to our mailing list now. 
        </p>
        <SubscribeForm
          validated={validated}
          onSubmit={handleSubmit}
          onChange={handleChange}
        />
        {displaySubscribeFormAlert && (
          <Alert
            variant={subscribeFormAlertType}
            onClose={() => setDisplaySubscribeFormAlert(false)}
            dismissible
          >
            {subscribeFormAlertType === "success"
              ? "You have been successfully subscribed to our mailing list. Thank you!"
              : "We were unable to subscribe you to our mailing list, please try again later."}
          </Alert>
        )}
        <br />
      </Section>
      <Footer /> 
    </React.Fragment>
  );
};

export default Subscribe;
