import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Alert, Button, Image } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";
import MediaPlayer from "./components/MediaPlayer";
import { longDateFormat } from "./utils/dates";

const Blog = () => {
  let { slug } = useParams();
  const [post, setPost] = useState();
  const [displayError, setDisplayError] = useState(false);
  const [postImage, setPostImage] = useState(
    "https://source.unsplash.com/featured/?technology&ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000&fit=max&ixid=eyJhcHBfaWQiOjExNzczfQ"
  );

  useEffect(() => {
    async function fetchPost() {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/ghost/posts/slug/${slug}`
        );
        setPost(result.data.posts[0]);
        if (result.data.posts[0].feature_image)
          setPostImage(result.data.posts[0].feature_image);
      } catch (error) {
        console.log(error);
        setDisplayError(true);
      }
    }
    fetchPost();
  }, [slug]);

  const customPostFields =
    post && post.codeinjection_head
      ? JSON.parse(post.codeinjection_head)
      : null;
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Aspiricity - {`${(post && post.title) || "Tech Nomad Blog"}`}
        </title>
      </Helmet>
      <Header>
        <h1>The Tech Nomad Blog</h1>
      </Header>
      <Section bgColor="bg-light" textColor="text-dark">
        {!post &&
          !displayError &&
          "Please wait while the Tech Nomad packs the digital donkeys..."}
        {post && (
          <React.Fragment>
            <div className="p-4">
              <h1>{post.title}</h1>
              <br />
              <p className="lead">{post.excerpt}</p>
              <hr />
              <p>
                {longDateFormat(new Date(post.published_at).toDateString())} |{" "}
                {post.reading_time} min read
              </p>
            </div>
            {customPostFields && customPostFields.mediaOnly ? (
              <MediaPlayer url={customPostFields.mediaURL} />
            ) : (
              <Image src={postImage} alt={post.title} fluid />
            )}
            <br />
            <br />
            <div className="ghost-text p-4">
              <p dangerouslySetInnerHTML={{ __html: post.html }}></p>
            </div>
            {customPostFields && !customPostFields.mediaOnly && (
              <React.Fragment>
                <MediaPlayer url={customPostFields.mediaURL} />
                <br />
                <hr />
              </React.Fragment>
            )}
            <p className="p-3 text-center">
              <Button as={Link} variant="primary" to="/blog">
                Back to the Tech Nomad blog page
              </Button>
            </p>
          </React.Fragment>
        )}
        {displayError && (
          <Alert
            variant="danger"
            onClose={() => setDisplayError(false)}
            dismissible
          >
            Uh oh! It seems like the Tech Nomad has wandered off course. We're
            sure he'll get back on track shortly, but until then head back to
            the <Link to={"/"}>Aspiricity home page</Link>.
          </Alert>
        )}
      </Section>
      <Footer />
    </React.Fragment>
  );
};

export default Blog;
