import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";

const About = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Aspiricity - About Us</title>
      </Helmet>
      <Header>
        <h1>About Us</h1>
      </Header>
      <Section bgColor="bg-light" textColor="text-dark">
        {/* <MediaPlayer url="https://www.youtube.com/watch?v=IO3lZrm2Y10" /> */}
        <p className="lead">
          Aspiricity started with a simple aspiration, to help others.
        </p>
        <p>
          What followed was an equally simple realization. If we find talented
          and driven people and help them overcome any barriers to starting
          careers in software development, they will provide truly amazing
          services for our customers.
        </p>
        <h3>What is Aspiricity?</h3>
        <p>
          We are a social impact business. We help people realize their
          potential and then enable them to deliver high quality yet affordable
          software development and testing consulting services to our customers.
        </p>
        <h3>Work with us!</h3>
        <p>
          Whether you are company looking for the next key contributor on your
          agile software development team or an individual looking for a
          rewarding career in software development, we can help. We are located
          in beautiful Calgary, Canada, but we're fully remote capable. Contact
          us today to get started!
        </p>
        <Button as={Link} variant="primary" to="/contact">
          Contact us
        </Button>
        <br />
      </Section>
      <Footer />
    </React.Fragment>
  );
};

export default About;
