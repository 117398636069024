import React from "react";
import ReactPlayer from "react-player";
import "./media-player.css";

const MediaPlayer = (props) => (
  <div className='player-wrapper'>
  <ReactPlayer
    controls
    className='react-player'
    url={props.url}
    width='100%'
    height='100%'
  />
</div>
);

export default MediaPlayer;
