import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";

const Consulting = () => (
  <React.Fragment>
    <Helmet>
      <title>Aspiricity - Consulting Services</title>
    </Helmet>
    <Header>
      <h2>Consulting Services</h2>
    </Header>
    <Section bgColor="bg-light" textColor="text-dark">
      <p className="lead">
        Need to add high quality, skilled developers and testers to your Agile
        development team? Look no further!
      </p>
      <p>
        We get it, smoothing out the kinks in your agile software development
        process is difficult and tedious. You need to focus on your strengths,
        building great software. Let us use our strengths to fill the resource
        gaps in your development, testing, and agile development teams.
      </p>
      <h3>Work with us!</h3>
      <p>
        At Aspiricity, our promise to you our customer is an unwavering
        commitment to integrity, quality and customer satisfaction and we focus
        on building the very same commitment into our consultants through our
        training. Contact us today to get started!
      </p>
      <Button as={Link} variant="primary" to="/contact">
        Contact us
      </Button>
      <br />
    </Section>
    <Footer />
  </React.Fragment>
);

export default Consulting;
