import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Section from "./components/Section";

const NotFound = () => (
  <React.Fragment>
    <Helmet>
      <title>Aspiricity - Page Not Found</title>
    </Helmet>
    <Header>
      <h1>Page Not Found</h1>
    </Header>
    <Section bgColor="bg-light" textColor="text-dark">
      <p className="lead">
        Uh oh, someone messed up. Let's not point fingers and just help you get
        back to where you need to be.
      </p>
      <p>
        <Button as={Link} variant="primary" to="/">
          Back to Home
        </Button>
      </p>
      <br />
    </Section>
    <Footer />
  </React.Fragment>
);

export default NotFound;
