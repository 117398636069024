import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav, Navbar, Button } from "react-bootstrap";
import styled from "styled-components";
import useEventListener from "./UseEventListener";
import logo from "../assets/logo.png";

const NavBarContainer = styled.div`
  .navbar {
    background: ${(props) => props.bgColor};
    transition: background-color 500ms linear;
    min-height: 4.5rem;
  }

  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: #efefef;
    text-shadow: #000000 1px 1px 2px;
    font-size: larger;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: #ffffff;
      text-shadow: #000000 1px 1px 3px;
      -webkit-font-smoothing: antialiased;
      text-decoration: none;
    }
    &:focus {
      color: #efefef;
      text-shadow: #000000 1px 1px 2px;
    }
  }

  .navbar-brand {
    font-size: x-large;
    img {
      max-height: 3rem;
    }
  }
`;

const defaultNavBarColor = "#343a40";

const NavLink = (props) => (
  <Nav.Item>
    <Nav.Link as={Link} to={props.url}>
      {props.children}
    </Nav.Link>
    {/* <Nav.Link as={Link} href={props.url}>{props.children}</Nav.Link> */}
  </Nav.Item>
);

const NavBar = (props) => {
  const [navbarColor, setNavbarColor] = useState("");
  const [displayLogo, setDisplayLogo] = useState(true);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [scrollDistance, setScrollDistance] = useState(0);

  let location = useLocation();
  useEffect(() => {
    setMenuExpanded(false);
    window.scrollTo(0, 0);
  }, [location]);

  useEventListener("scroll", () => {
    setScrollDistance(window.pageYOffset || document.documentElement.scrollTop);
  });

  useEffect(() => {
    if (!menuExpanded && scrollDistance < 50) {
      setNavbarColor("");
      setDisplayLogo(true);
    } else {
      setNavbarColor(props.bgColor || defaultNavBarColor);
      setDisplayLogo(false);
    }
  }, [props.bgColor, menuExpanded, scrollDistance])

  const handleToggle = () => {
    setMenuExpanded(!menuExpanded);
  }

  return (
    <NavBarContainer bgColor={navbarColor}>
      <Navbar expanded={menuExpanded} expand="md" fixed="top" onToggle={handleToggle}>
        <Navbar.Brand as={Link} to="/">
          {displayLogo && <img src={logo} alt="Aspiricity Logo" />}
          {!displayLogo && <React.Fragment>Aspiricity</React.Fragment>}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-nnavbar-nav">
          <Nav className="ml-auto">
            <NavLink url="/consulting">Consulting</NavLink>
            <NavLink url="/training">Training</NavLink>
            <NavLink url="/blog">Blog</NavLink>
            <NavLink url="/subscribe">Subscribe</NavLink>
            <NavLink url="/about">About Us</NavLink>
            <Button as={Link} variant="dark" to="/contact">
              Contact Us
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </NavBarContainer>
  );
};

export default NavBar;
