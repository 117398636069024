import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const AnchorButton = (props) => {
    if (!props.to) return <span>{props.children}</span>;
    if (/^https?:\/\//.test(props.to)) return <Button href={props.to}>{props.children}</Button>;
    return <Button as={Link} to={props.to}>{props.children}</Button>
}

export default AnchorButton;